import { render, staticRenderFns } from "./countries.vue?vue&type=template&id=0baf6cc9&scoped=true"
import script from "./countries.vue?vue&type=script&lang=js"
export * from "./countries.vue?vue&type=script&lang=js"
import style0 from "./countries.vue?vue&type=style&index=0&id=0baf6cc9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0baf6cc9",
  null
  
)

export default component.exports